<template>
  <v-container class="back justify-content" id='NotFound' total_height fluid>
    <div class="relative">
      <h1 style="text-aling: center; font-size: 120px; color: #066f6a;">
        Erro 404
      </h1>
      <br />
      <span>
        <a class='notfound' @click="$router.push({ name: 'home' })">
          <h1 style="text-aling: center; font-size: 40px;">
            <b> Retornar para principal? </b>
          </h1>
        </a>
      </span>
      <img
        src="../assets/notfound/555.png"
        align="center"
        style="position: relative; height: 250px;"

      />
    </div>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="sass" scoped>
.back
  background: linear-gradient(0deg, rgba(255,255,255, 0.5), rgba(102,102,102, 0.2))
  background-size: cover
  // opacity: 0.1

.total_height
  height: 100vh

.relative
  position: relative
  top: 180px
  left: 700px
  width: 550px
  height: 500px
  justify-content: space-between
  align-items: center
  flex-flow: column wrap

.notfound
  display: block
  cursor: pointer
  font-weight: 500
  color: #d1ae6c
  &:hover, &:focus
    b
      color: #066f6a
  &:active
    b
      color: accent

</style>
